import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => (
  <Layout>
    <p>
      The page you're looking for does not exist.
    </p>
    <p>
      <Link to="/">Click here</Link> to see the list of episodes of The No Signal Show.
    </p>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`